// 訂單狀態
const verificationType = {
    0: "待審核",
    1: "審核通過",
    "-1": "未通過",
};

export default {
    verificationType,
};
