<template>
    <div>
        <LoadingComponent :isLoading="loading" />
        <!-- 第一階段 資訊 -->
        <div class="detail-info bg-white rounded-lg mt-4">
            <div class="salary-info">
                <!-- 第一階段審核資料 -->
                <div class="section-title">
                    第一階段驗證資訊
                    <span v-if="firstStepInfo.status !== null">({{ firstStepInfo.status | verificationType }})</span>
                </div>
                <div class="section-content">
                    <div v-if="firstStepInfo.status !== null" class="top">
                        <div class="top-left">
                            <div class="item">
                                <div class="label">身份證正面</div>
                                <div class="value">
                                    <img :src="firstStepInfo.attachment.id_card_front.url" alt="" />
                                </div>
                            </div>
                            <div class="item">
                                <div class="label">用戶 ID</div>
                                <div class="value">{{ id }}</div>
                            </div>
                            <div class="item">
                                <div class="label">真實姓名</div>
                                <div class="value">{{ firstStepInfo.user.name }}</div>
                            </div>
                        </div>
                        <div class="top-right">
                            <div class="item">
                                <div class="label">身份證反面</div>
                                <div class="value">
                                    <img :src="firstStepInfo.attachment.id_card_back.url" alt="" />
                                </div>
                            </div>
                            <div class="item">
                                <div class="label">身份證號碼</div>
                                <div class="value">{{ firstStepInfo.user.government_id }}</div>
                            </div>
                            <div class="item">
                                <div class="label">手機</div>
                                <div class="value">{{ firstStepInfo.user.phone }}</div>
                            </div>
                        </div>
                    </div>
                    <div v-else>尚未上傳資料</div>
                </div>
                <!-- 第一階段審核操作 -->
                <div v-if="firstStepInfo.status === 0" style="border-top: 2px solid #cbcbcb">
                    <div class="section-title">審核結果</div>
                    <div class="section-inputs">
                        <div>
                            <el-radio v-model="inputs.status" :label="1">審核通過</el-radio>
                        </div>
                        <div style="margin-top: 15px">
                            <el-radio v-model="inputs.status" :label="-1">審核未通過</el-radio>
                        </div>
                        <el-input v-model="inputs.comment" style="margin: 20px" type="textarea" placeholder="請輸入未通過原因" />
                    </div>
                    <div class="actions">
                        <!-- <button class="black-btn-outline-lg text-md mr-5" @click="backList">返回列表</button> -->
                        <el-button v-permission="['update']" class="orange-btn-800-lg text-md" @click="update(firstStepInfo.id)">送出結果</el-button>
                    </div>
                </div>
                <!-- 第一階段審核紀錄 -->
                <div v-if="firstStepHistory.length > 0" class="salary-info" style="margin-top: 40px">
                    <div class="section-title">審核記錄</div>
                    <table class="myTable">
                        <thead>
                            <tr>
                                <th>審核時間</th>
                                <th>審核結果</th>
                                <th>審核人員</th>
                                <th>審核資料</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(i, idx) in firstStepHistory" :key="idx">
                                <td>{{ i.closed_at }}</td>
                                <td>
                                    {{ i.status | verificationType }}
                                    <div v-if="i.status == -1">原因：{{ i.comment }}</div>
                                </td>
                                <td>{{ i.administrator.comment }}</td>
                                <td class="blue-link" @click="openDialog(i)">查看審核資料</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <!-- 第二階段 資訊 -->
        <div class="detail-info bg-white rounded-lg">
            <div class="salary-info">
                <!-- 第二階段審核資料 -->
                <div class="section-title">
                    第二階段驗證資訊
                    <span v-if="secondStepInfo.status !== null">({{ secondStepInfo.status | verificationType }})</span>
                </div>
                <div class="section-content">
                    <div v-if="secondStepInfo.status !== null" class="top">
                        <div class="top-left">
                            <div class="item">
                                <div class="label">個人正面照片</div>
                                <div class="value">
                                    <img :src="secondStepInfo.attachment.photo.url" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="top-right"></div>
                    </div>
                    <div v-else>尚未上傳資料</div>
                </div>
                <!-- 第二階段審核操作 -->
                <div v-if="secondStepInfo.status === 0 && firstStepInfo.status == 1" style="border-top: 2px solid #cbcbcb">
                    <div class="section-title">審核結果</div>
                    <div class="section-inputs">
                        <div>
                            <el-radio v-model="inputs.status" :label="1">審核通過</el-radio>
                        </div>
                        <div style="margin-top: 15px">
                            <el-radio v-model="inputs.status" :label="-1">審核未通過</el-radio>
                        </div>
                        <el-input v-model="inputs.comment" style="margin: 20px" type="textarea" placeholder="請輸入未通過原因" />
                    </div>
                    <div class="actions">
                        <!-- <button class="black-btn-outline-lg text-md mr-5" @click="backList">返回列表</button> -->
                        <el-button v-permission="['update']" class="orange-btn-800-lg text-md" @click="update(secondStepInfo.id)">送出結果</el-button>
                    </div>
                </div>
                <!-- 第二階段審核紀錄 -->
                <div v-if="secondStepHistory.length > 0" class="salary-info" style="margin-top: 40px">
                    <div class="section-title">審核記錄</div>
                    <table class="myTable">
                        <thead>
                            <tr>
                                <th>審核時間</th>
                                <th>審核結果</th>
                                <th>審核人員</th>
                                <th>審核資料</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(i, idx) in secondStepHistory" :key="idx">
                                <td>{{ i.closed_at }}</td>
                                <td>
                                    {{ i.status | verificationType }}
                                    <div v-if="i.status == -1">原因：{{ i.comment }}</div>
                                </td>
                                <td>{{ i.administrator.comment }}</td>
                                <td class="blue-link" @click="openDialog(i)">查看審核資料</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="actions w-[100%] flex justify-center">
            <button class="black-btn-outline-lg text-md justify-self-center" @click="backList">返回列表</button>
        </div>
        <!-- 審核歷史資料彈窗 -->
        <el-dialog title="審核歷史資料" :visible.sync="dialogVisible" width="30%">
            <div class="detail-info" style="margin: 0">
                <div class="section-content">
                    <div v-if="disloogData.level == 1" class="top flex">
                        <div class="top-left">
                            <div class="item">
                                <div class="label">身份證正面</div>
                                <div class="value">
                                    <img :src="disloogData.attachment.id_card_front.url" alt="" />
                                </div>
                            </div>
                            <div class="item">
                                <div class="label">用戶 ID</div>
                                <div class="value">{{ id }}</div>
                            </div>
                            <div class="item">
                                <div class="label">真實姓名</div>
                                <div class="value">{{ disloogData.user.name }}</div>
                            </div>
                        </div>
                        <div class="top-right">
                            <div class="item">
                                <div class="label">身份證反面</div>
                                <div class="value">
                                    <img :src="disloogData.attachment.id_card_back.url" alt="" />
                                </div>
                            </div>
                            <div class="item">
                                <div class="label">身份證號碼</div>
                                <div class="value">{{ disloogData.user.government_id }}</div>
                            </div>
                            <div class="item">
                                <div class="label">手機</div>
                                <div class="value">{{ disloogData.user.phone }}</div>
                            </div>
                        </div>
                    </div>
                    <div v-else><img :src="secondStepInfo.attachment.photo.url" alt="" /></div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import LoadingComponent from "@/components/Loading.vue";
import "@/global/filters/filterVerification";
// 驗證資料預設值
const defaultStepInfo = {
    status: null,
    attachment: {
        id_card_back: {
            id: "",
            url: "",
        },
        id_card_front: {
            id: "",
            url: "",
        },
        photo: {
            url: "",
        },
    },
    user: {
        name: "",
    },
};
export default {
    name: "VerificationsDetail",
    components: {
        LoadingComponent,
    },
    computed: {
        // 第一階審核資料
        firstStepInfo() {
            let result = {};
            const hasSuccess = this.datas.find((i) => i.level == 1 && i.status === 1);
            if (hasSuccess) {
                result = { ...hasSuccess };
            } else {
                result = this.datas.find((i) => i.level == 1 && i.status === 0);
            }
            return result ?? { ...defaultStepInfo };
        },
        // 第一階段歷史紀錄
        firstStepHistory() {
            const result = this.datas.filter((i) => i.level == 1 && i.administrator_id);
            return result;
        },
        // 第二階審核資料
        secondStepInfo() {
            let result = {};
            const hasSuccess = this.datas.find((i) => i.level == 2 && i.status === 1);
            if (hasSuccess) {
                result = { ...hasSuccess };
            } else {
                result = this.datas.find((i) => i.level == 2 && i.status === 0);
            }
            return result ?? { ...defaultStepInfo };
        },
        // 第二階段歷史紀錄
        secondStepHistory() {
            const result = this.datas.filter((i) => i.level == 2 && i.administrator_id);
            return result;
        },
    },
    data() {
        return {
            loading: false,
            // 當前使用者驗證資訊
            id: 0,
            info: { ...defaultStepInfo },
            datas: [],
            inputs: {
                status: 1,
                comment: "",
            },
            dialogVisible: false,
            // 彈窗預設值
            disloogData: { ...defaultStepInfo },
            inputSignificant: false, // 重點註記輸入匡
            showSignificant: false,
        };
    },
    methods: {
        async getData() {
            this.loading = true;
            const { status, data } = await this.$api
                .SearchAuthenticationsApi({ user_id: this.id })
                .then((res) => {
                    return { data: res.data.data, status: res.status };
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
            if (status === 200) {
                this.datas = [...data];
            }
        },
        // 更新資料
        update(applyID) {
            if (this.inputs.status === -1 && !this.inputs.comment) {
                alert("請輸入未通過原因");
                return;
            }
            const param = {
                ...this.inputs,
                comment: this.inputs.status === -1 ? this.inputs.comment : "審核通過",
            };
            this.$api.ApplyAuthenticationsIDApi(applyID, param).then(() => {
                this.$message({
                    type: "success",
                    message: "更新成功",
                });
                this.getData();
                this.inputs = {
                    status: 1,
                    comment: "",
                };
            });
        },
        // 導頁相關
        backList() {
            this.$router.push({
                name: "wait_for_check",
            });
        },
        // 查看審核歷史
        openDialog(i) {
            this.dialogVisible = true;
            this.disloogData = { ...i };
        },
    },
    mounted() {
        this.id = this.$route.params.id;
        this.getData();
    },
    activated() {
        this.id = this.$route.params.id;
        this.getData();
    },
};
</script>
<style lang="scss" scrope>
@import "@/scss/_text-style.scss";

.action-bar {
    background: white;
    color: chocolate;
    margin: 10px 0;
    height: 55px;
}

.blue-link {
    color: blue;
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
    &.no-line {
        text-decoration: none;
    }
}

.border-orange {
    border: 3px dashed #ff5733;
}

.detail-info {
    padding: 30px 8%;
    margin-bottom: 20px;
    .section-title {
        width: 80%;
        margin: 20px 0;
        @extend .text-black-blod-18;
    }
    .section-content {
        margin-top: 20px;
        overflow: scroll;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        padding-bottom: 30px;
        .top {
            display: flex;
            width: 100%;
            .top-left {
                flex: 1;
                margin-right: 50px;
            }
            .top-right {
                flex: 1;
            }
        }

        .item {
            padding-bottom: 30px;
            .label {
                color: #757575;
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 10px;
            }
            .value {
                margin-top: 10px;
            }
        }

        // .bottom {
        //     margin-top: 10px;
        //     width: 100%;
        //     .input {
        //         margin-top: 20px;
        //         text-align: left;
        //     }

        //     .label {
        //         color: #757575;
        //         font-size: 14px;
        //         font-weight: 500;
        //         margin-bottom: 10px;
        //     }
        // }
    }
    .actions {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 30px;
    }

    .actions2 {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 10px;
    }
    .myTable {
        border-radius: 20px;
        border: 1px solid #ccc;
        text-align: left;
    }

    .submit {
        margin-top: 20px;
    }
}

.images {
    width: 100%;
    overflow: scroll;
    display: flex;
    align-items: center;
    .image {
        display: flex;
        position: relative;
        width: 150px;
        min-width: 150px;
        height: 150px;
        margin: 10px 5px;
        background-size: contain !important;
        .icon:hover {
            cursor: pointer;
        }
        &:hover {
            .watch {
                width: 100%;
                height: 100%;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                background: rgba(0, 0, 0, 0.2);
                font-size: 20px;
            }
            .trash {
                width: 100%;
                height: 100%;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                background: rgba(0, 0, 0, 0.2);
                font-size: 20px;
            }
        }
        .watch {
            display: none;
        }
        .trash {
            display: none;
        }
    }
}

.hasSign {
    background: #ff573324 !important;
}
</style>
