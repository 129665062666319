import Vue from "vue";
import userConfig from "@/config/userConfig.js";
// 客訴單問題類型
Vue.filter("verificationType", (val) => {
    const types = userConfig.verificationType;
    if (types[val]) {
        return types[val];
    }
    return val;
});
